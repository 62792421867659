/*******************************
         Site Overrides
*******************************/


.ui.big.buttons .button,
.ui.big.buttons .or,
.ui.big.button {
  font-size: @big;
  line-height: 22px;
}
