/*******************************
        User Overrides
*******************************/

@font-face {
  font-family: 'Dropdown Decoded';
  src: url('/fonts/Semantic/caret.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ui.dropdown > .label {
  background-color: @SecondaryBlue10;
  color: @SecondaryBlue;
  font-weight: 400;

  &:hover {
    background-color: @SecondaryBlue20;
    color: @SecondaryBlue;
    font-weight: 400;
  }
}

.ui.dropdown > .dropdown.icon {
  font-family: 'Dropdown Decoded';
}

