/*******************************
         Site Overrides
*******************************/

// from the docs :
// "Perfectly circular images require a perfectly square image file." which is a bit stupid
// https://react.semantic-ui.com/elements/image/#circular

// this allows for non-square images to fit in the circle nicely
.ui.image.circular {
    object-fit: cover;
}
