/*******************************
        User Overrides
*******************************/

@font-face {
  font-family: 'Accordion Decoded';
  src: url('/fonts/Semantic/caret.ttf') format('truetype'),
    url('/fonts/Semantic/caret.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ui.accordion .title .dropdown.icon,
.ui.accordion .accordion .title .dropdown.icon {
  font-family: 'Accordion Decoded';
}

