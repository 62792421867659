/*******************************
         Site Overrides
*******************************/

.ui.modal>.header {
  text-align: center;

  .subheader {
    font-family: Graphik;
    font-size: 14px;
    font-weight: 400;
    line-height: 15px;
    color: @SecondarySkyBlue;

    &.description {
      color: @PrimaryCobalt80;
    }

    &.title {
      margin-top: 16px;
      font-family: Graphik;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
    }
  }

}

.ui.modal {
  .close.icon {
    position: absolute;
    top: 1rem;
    right: 1.5rem;
    color: #000000;
    opacity: 0.2;

    &::before {
      font-family: verdana;
      content: '✕';
      font-weight: bold;
    }

    &:hover {
      opacity: 0.4;
    }
  }
}

.ui.modal .modal-main-description {
  font-family: Graphik;
  font-size: 14px;
  font-weight: 500;
  color: @PrimaryCobalt;
  margin-bottom: 1.5em;
}

// ACTIONS

// If no modal content
.ui.modal>.header+.actions {
  padding-top: 24px;
}

.ui.modal>.actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  &.align-center {
    justify-content: center;
    .button {
      margin-right: 9px;
      margin-left: 9px;
    }
  }

  .align-left {
    display: flex;
    justify-content: flex-start;
    flex-grow: 1;

    button:first-child {
      margin-left: 0;
      padding-left: 0;
    }
  }
}

.ui.modal>.actions button:last-child {
  margin-right: 0;
}

.ui.modal>.actions button.big {
  font-size: 18px;
  line-height: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.ui.modal>.actions button.primary, .ui.modal>.actions button.secondary {
  min-width: 106px;
}

.ui.modal>.actions button.dismiss {
  background-color: @white;
  color: @SecondaryBlue;
  font-size: 18px;
  font-weight: 700;
  padding-top: 10px;
  padding-bottom: 10px;
  line-height: 18px;

  &:hover {
    color: @SecondaryBlue80;
  }

  &:focus {
    color: @SecondaryBlue120;
  }
}


// FORM

.ui.modal .input-container {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }

  .input-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 15px;
    margin-bottom: 8px;
    color: @PrimaryCobalt80;
  }

  .input-description {
    font-size: 12px;
    color: @PrimaryCobalt40;
    margin-bottom: 8px;
  }

  .input-error {
    font-size: 12px;
    color: @NegativeColor;
  }
}

.ui.modal .inputs-row {
  display: flex;
  flex-direction: row;

  &.align-right {
    justify-content: flex-end;
    .input-container {
      &.half-width:first-child:last-child {
        padding-right: 0px;
        padding-left: 12px;
      }
    }
  }

  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0px;
  }

  .input-container {
    margin-bottom: 0;

    &.half-width {
      width: 50%;
    }

    padding-right: 12px;
    padding-left: 12px;

    &:first-child {
      padding-left: 0px;
    }

    &:last-child {
      padding-right: 0px;
    }

    &.half-width:first-child:last-child {
      padding-right: 12px;
    }
  }
}
