/*******************************
         Site Overrides
*******************************/

@font-face {
  font-family: 'Step Decoded';
  src: url('/fonts/Semantic/caret.ttf') format('truetype'),
    url('/fonts/Semantic/caret.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ui.steps .step.completed > .icon:before,
.ui.ordered.steps .step.completed:before {
  font-family: 'Step Decoded';
}
