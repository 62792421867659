/*******************************
         Site Overrides
*******************************/

@font-face {
  font-family: 'Rating Decoded';
  src: url('/fonts/Semantic/caret.ttf') format('truetype'),
    url('/fonts/Semantic/caret.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.ui.rating .icon {
  font-family: 'Rating Decoded';
}

